import App from './app';
import * as ReactDOM from 'react-dom';
import * as React from 'react';
import { CookiesProvider } from 'react-cookie';

ReactDOM.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>,
  document.getElementById('app')
);
