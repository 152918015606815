import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import useFetch from "../../hooks/useFetch";
import { Page } from '../page';
import { TitledBlock } from '../../components/blocks/titledBlock/titledBlock';

import {Field, Form, Formik} from "formik";
import {FormikCheckbox, FormikPhoneField, FormikTextField, FormWrapper} from "../../components/formik/formik.styles";
import {Button} from "../../components/buttons/button";
import {AlertMesssage} from "../../components/blocks/alertMessage/alertMessage";
import {CancelButton, NoticeText} from "../newBankAccount/newBankAccount.styles";
import {useHistory} from "react-router-dom";

type FormValues = {
    first_name: string,
    last_name: string,
    phone: string,
    email: string,
    newsletter: boolean
};

const SettingsPage = () => {
    const [saveObj, doSave] = useFetch('savesettings');
    const [loadObj, doLoad] = useFetch('loadsettings');
    const history = useHistory();
    const [initialValues, setInitialValues] = useState<FormValues>({
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        newsletter: false
    })

    useEffect(() => {
        doLoad();
    }, [])

    useEffect(() => {
        if(loadObj.responses) {
            setInitialValues(loadObj.responses.user);
        }
    }, [loadObj.responses])

    const handleSave = (values:any) => {
        const data = {}
        doSave({
            method: 'post',
            data: values
        })
    }

    const validate = (values: any) => {
        const errors: any = {};

        if (!values.phone) errors.phone = 'Required';
        else if (values.phone.length < 7) errors.phone = 'Must be at least 7 digits';

        return errors;
    };

    return (
        <Page title='Settings' tabNavigation={true}>
            <TitledBlock title='Settings'>
                <NoticeText>
                    Update your personal information
                </NoticeText>
            </TitledBlock>
            <br/>
            {(saveObj.errors || saveObj.responses) && <AlertMesssage type={saveObj.errors ? 'error' : 'success'} message={saveObj.errors ? saveObj.errors.message : saveObj.responses.message}></AlertMesssage>}
            <FormWrapper>
                <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={handleSave} validate={validate}>
                    {({ isSubmitting }) => (
                        <Form translate="yes">
                            <Field
                                name="first_name"
                                label="First Name"
                                disabled={true}
                                component={FormikTextField}
                            />
                            <Field
                                name="last_name"
                                label="Last Name"
                                disabled={true}
                                component={FormikTextField}
                            />
                            <Field
                                name="email"
                                label="Email"
                                disabled={true}
                                component={FormikTextField}
                            />
                            <Field
                                name="phone"
                                label="Phone number"
                                component={FormikPhoneField}
                            />
                            <Field
                                name="newsletter"
                                label="Subscribe to newsletter"
                                component={FormikCheckbox}
                            />
                            <br/>
                            <Button type="submit" disabled={isSubmitting}>Save Settings</Button>
                            <CancelButton type="button" onClick={() => history.push(`/logout`)}>Logout</CancelButton>
                        </Form>
                    )}
                </Formik>
            </FormWrapper>
        </Page >
    );
}


export default SettingsPage;