import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const PayButton = styled.div`
  border-width: 0;
  background-color: #4bb43e;
  padding: 10px;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 700;
  color: white;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover {
    background-color: #419c35;
  }
`;

export const PayLink = styled(Link)`
  text-decoration: none;
`;

export const AutopayButton = styled(PayButton)`
  padding: 6px 12px;
  background-color: #2d88e5;

  &:hover {
    background-color: #297acc;
  }
`;

export const BoldText = styled.div`
  color: #a7a8aa;
  font-size: 13px;
  font-weight: 700;
`;

export const BigGreenText = styled.div`
  color: #509c5b;
  font-weight: 700;
  line-height: 24px;
  font-size: 18px;
`;

export const DueDateText = styled.div`
  font-style: italic;
  font-weight: 300;
  color: #a7a8aa;
`;

export const Transactions = styled.div`
margin: 0px 10px;
div:not(:first-child):not(:last-child) {
  border-bottom: 1px #ddd solid;
}
`;

export const HeaderText = styled.span<{single?: boolean; indent?: boolean}>`
  flex: ${(p) => p.single ? 1 : 2};
  text-indent: ${(p) => p.indent ? '15px' : ''};
  margin-bottom: 15px;
  color: rgba(88,82,82,.76);
  font-size: 13px;
  font-weight: 700;
`;

export const TransactionText = styled.span`
  flex: ${(p: {single?: boolean}) => p.single ? 1 : 2};
  color: #a7a8aa;
  font-size: 12px;
  font-weight: 700;
`;

export const TransactionAmount = styled.span<{single?: boolean; negative?: boolean}>`
  flex: ${(p) => p.single ? 1 : 2};
  color: ${(p) => p.negative ? '#f94247' : '#509c5b'};
  font-weight: 700;
  line-height: 23px;
  font-size: 16px;
`;
