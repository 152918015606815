import * as React from 'react';
import { Tab, TabInterface } from './tab';
import { Container } from './tabNavigation.styles';

interface Props {
  tabs: TabInterface[];
}

export class TabNavigation extends React.Component<Props, {}> {
  public render() {
    return (
      <Container>
        {this.props.tabs.map((tab) => <Tab data={tab} key={tab.iconURL}/>)}
      </Container>
    );
  }
}
