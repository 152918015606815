import * as React from 'react';
import {FC, useContext, useEffect, useState} from 'react';
import { TextInput } from '../../components/inputs/textInput/textInput';
import { Page } from '../page';
import {Button, FormButton} from '../../components/buttons/button';
import useFetch from '../../hooks/useFetch'
import useLocalStorage from '../../hooks/useLocalStorage'
import { PageWrapper, LoginWrapper, InputSeparator, NameWrapper, Name, LoginLink, TextLine } from './login.styles';
import {AlertMesssage} from "../../components/blocks/alertMessage/alertMessage";
import {CurrentUserContext} from "../../contexts/currentUser";
import {Redirect} from "react-router";
import {Link} from "react-router-dom";
import {FormikInput, FormikTextField, FormWrapper} from "../../components/formik/formik.styles";
import {NoticeText} from "../newBankAccount/newBankAccount.styles";
import {TitledBlock} from "../../components/blocks/titledBlock/titledBlock";
import {Field, Form, Formik} from "formik";

interface Props {
  token: string;
}

export const ForgotPage : FC<Props> = (props):JSX.Element =>  {
    const [email, setEmail] = useState<string>('')
    const [password1, setPassword1] = useState<string>('')
    const [password2, setPassword2] = useState<string>('')
    const [redirect, setRedirect] = useState<any>(null)
    const [emailObj, doEmail] = useFetch('forgot');
    const [resetObj, doReset] = useFetch('resetpassword');

    const handleReset = (values: any) => {
        values.token = props.token;
        doReset({
          method: 'post',
          data: values
        })
    }

    const handleEmail = () => {
        const data = {email: email}
        doEmail({
            method: 'post',
            data: data
        })
    }

    const validate = (values: any) => {
        const errors: any = {};

        if (values.password1 != values.password2) errors.password2 = 'Passwords don\'t match';
        else if (values.password1.length < 8) errors.password1 = 'Must be at least 8 symbols';

        return errors;
    };

    return (
        <Page title='Forgot password' tabNavigation={false} checkAuth={false}>
          {redirect}
            <TitledBlock title='Forgot password'>
                <NoticeText>
                    {props.token ? "Choose and confirm your new password" : "Enter your email below to receive the link to reset your password"}
                </NoticeText>
            </TitledBlock>

              {(emailObj.errors || emailObj.responses) && <AlertMesssage type={emailObj.errors ? 'error' : 'success'} message={emailObj.errors ? emailObj.errors.message : emailObj.responses.message}></AlertMesssage>}
              {(resetObj.errors || resetObj.responses) && <AlertMesssage type={resetObj.errors ? 'error' : 'success'} message={resetObj.errors ? resetObj.errors.message : resetObj.responses.message}></AlertMesssage>}
            <FormWrapper>
              {!emailObj.responses && !props.token && getFields()}
              {!resetObj.responses && props.token && getPasswordFields()}
              {(emailObj.responses && !props.token || resetObj.responses) && <LoginLink to="/login">Login</LoginLink>}
            </FormWrapper>
        </Page>
      );

    function getFields() {
        return(
            <div>
                <TextLine>Enter your email to reset your password:</TextLine>

                <FormikInput
                    placeholder='Your email'
                    type='text'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <br/>
                <br/>
                <Button onClick={handleEmail}>
                  Send recovery link
                </Button>
            </div>
        );
    }

    function getPasswordFields() {
        return(
            <FormWrapper>
                <br/>
                <Formik enableReinitialize={true} initialValues={{'password1': '', 'password2' : ''}} onSubmit={handleReset} validate={validate}>
                    {({ isSubmitting }) => (
                        <Form translate="yes">
                            <Field
                                name="password1"
                                type="password"
                                label="Choose password"
                                component={FormikTextField}
                            />
                            <Field
                                name="password2"
                                type="password"
                                label="Confirm password"
                                component={FormikTextField}
                            />
                            <br/>
                            <Button type="submit" disabled={isSubmitting}>Reset Password</Button>
                        </Form>
                    )}
                </Formik>
            </FormWrapper>
        );
    }
}
export default ForgotPage;