import * as React from 'react';
import { Container } from './titledBlock.styles';
import { Wrapper, Title, Content } from './titledBlock.styles';

interface Props {
  title: string;
  emptyTitle?: boolean;
}

export class TitledBlock extends React.Component<Props, {}> {
  public render() {
    return (
      <Container>
        <Wrapper>
          <Title emptyTitle={this.props.emptyTitle}>{this.props.title}</Title>
          <Content>{this.props.children}</Content>
        </Wrapper>
      </Container>
    );
  }
}
