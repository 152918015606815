import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';


export const Container = styled.div<{visible: boolean, type: string}>`
  width: 100%;
  margin-bottom: 8px;
  padding:10px;  
  box-sizing: border-box;
  display:${state => state.visible ? 'block' : 'none'};
  ${(state: {type: string}) => {
    return state.type == 'error' ?
      css`
        color: #D8000C;
        background-color: #FFBABA;
      ` :
      css`
        color: #4F8A10;
        background-color: #DFF2BF;
      `;
  }}
  
`;

export const Message = styled.div`  
  font-size: 12px;
`;
