import * as React from 'react';
import { useState, useEffect, useContext } from "react";
import useFetch from "../../hooks/useFetch";
import { useHistory } from 'react-router-dom';
import { CurrentUserContext } from "../../contexts/currentUser";
import { Page } from '../page';
import { FormButton } from '../../components/buttons/button';
import { TitledBlock } from '../../components/blocks/titledBlock/titledBlock';
import { AlertMesssage } from "../../components/blocks/alertMessage/alertMessage";
import {
    Row, FormBlock, FormItem, Title, TitleInput, ErrorMessage, SubmitButtonWrap, CancelButton,
    BigGreenText, DueDateText, NewAccountLink, NewAccountButton, RadioGroup, RadioButton,
    RadioInput, RadioLabel, PayButton, PayLink, AutopayButton, BoldText, AutopayOffButton
} from './autopay.styles';
import { useFormik, Field, FormikProvider } from 'formik';
import { Redirect } from "react-router";

const AutopayPage = () => {

    const [dueDate, setDueDate] = useState<string>('Loading...');
    const [autopayInfo, setAutoPayInfo] = useState<any>(null);
    const [days, setDays] = useState<number>(null);
    const [balance, setBalance] = useState<any>('Loading...');
    const [leaseId, setLeaseId] = useState<number>(null);
    const [accounts, setAccounts] = useState<any>([]);
    const [error, setError] = useState<any>(null);
    const [reference, setReference] = useState(null);
    const [isTotal, setIsTotal] = useState<boolean>(true);
    const [createAccount, setCreateAccount] = useState<boolean>(false);
    const [accountsLoaded, setAccountsLoaded] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [{ responses }, doFetch] = useFetch('balance');
    const [accountsResult, fetchAccounts] = useFetch('getBankAccounts');
    const [autopayResult1, fetchAutopay] = useFetch('getRecurring');
    const [autopayResult, setupAutopay] = useFetch('enableRecurring');
    const [autopayOffResult, deleteAutopay] = useFetch('enableRecurring');
    const history = useHistory();
    const [currentUserState] = useContext(CurrentUserContext);

    // Formik declaration & submission
    const formik = useFormik({
        initialValues: { amount: '', bank_account: '', end_date: 'lease_end' },
        validate,
        onSubmit: values => {
            setIsSubmitting(true);
            const profile_id = currentUserState.currentUser.id;
            const full_name = `${currentUserState.currentUser.first_name} ${currentUserState.currentUser.last_name}`;

            const options = { type: 'month', interval: 1 }
            const data = { ...values, ...options, full_name, profile_id, enable: true };
            console.log(data);
            setupAutopay({ method: 'POST', data });
        }
    });

    useEffect(() => {
        doFetch({ method: 'get' })
        fetchAccounts({ method: 'get' })
        fetchAutopay({ method: 'get' })
    }, []);

    useEffect(() => {
        if (!responses) return;
        setBalance(responses.rent);
        setDays(responses.days);
        setDueDate(responses.duedate);
        setLeaseId(responses.lease_id);
        formik.setFieldValue('amount', parseFloat(responses.balance.replace('$', '').replaceAll(',', '')));
    }, [responses]);

    useEffect(() => {
        if (autopayResult.errors) {
            setError(autopayResult.errors.message);
            setIsSubmitting(false);
        }
        if (!autopayResult.responses) return;
        history.push({
            pathname: '/autopay_confirmation',
            state: {
                start_date: autopayResult.responses.start_date
            },
        });
        setIsSubmitting(false);
    }, [autopayResult.responses, autopayResult.errors]);

    useEffect(() => {
        if (autopayOffResult.errors) {
            setError(autopayOffResult.errors.message);
            setIsSubmitting(false);
        }
        if (!autopayOffResult.responses) return;
        window.location.reload();
    }, [autopayOffResult.responses, autopayOffResult.errors]);

    useEffect(() => {
        if (autopayResult1.responses == null) return;
        setAutoPayInfo(autopayResult1.responses);
    }, [autopayResult1.responses]);

    useEffect(() => {
        if (!accountsResult.responses) return;
        setAccountsLoaded(true);
        if (!accountsResult.responses.count) {
            setCreateAccount(true);
            return;
        }
        setAccounts(accountsResult.responses.accounts);
        formik.setFieldValue('bank_account', accountsResult.responses.accounts[0].id);
    }, [accountsResult.responses]);

    const onRadioChange = (e: any) => {
        e.persist();
        if (e.target.value === "total")
            formik.setFieldValue('amount', parseFloat(balance.replace('$', '').replaceAll(',', '')));
        else if (e.target.value === "custom") formik.setFieldValue('amount', '', false);

        setIsTotal(!isTotal);
    }

    const deleteRecurring = (e: any) => {
        deleteAutopay({ method: 'POST', data: {enable: false}});
    }

    const accountList: any = accounts.map((acc: any) => {
        return <option key={acc.id} value={acc.id}>{acc.nickname} ***{acc.account}</option>
    })

    function autopayOn() {

        console.log(autopayInfo);

        const { StartDate, NextScheduledRunTime, Amount } = autopayInfo;

        return (
            <div>
                <TitledBlock title='Recurring Payments'>
                    {error && <AlertMesssage type={'error'} message={error}></AlertMesssage>}
                    <Row show={true} style={{ justifyContent: 'space-between' }}>
                        <div style={{ paddingRight: 10, textAlign: 'left' }}>
                            <BigGreenText>Autopay is On</BigGreenText>
                            <DueDateText>Created on {StartDate}</DueDateText>
                            <BoldText>{autopayInfo.baccount}</BoldText>
                        </div>

                        <div>
                            <div>
                                <AutopayButton onClick={deleteRecurring}>Turn off Autopay</AutopayButton>
                            </div>
                        </div>

                    </Row>
                </TitledBlock>
                <TitledBlock title='Next Payment Details'>
                    <Row show={true}>
                        <BoldText style={{ flex: 2 }}>Your next payment is scheduled on: </BoldText><BigGreenText>{NextScheduledRunTime.split(' ')[0]}</BigGreenText>
                    </Row>
                    <Row show={true}>
                        <BoldText style={{ flex: 2 }}>Amout of the next payment is: </BoldText><BigGreenText>${Amount}</BigGreenText>
                    </Row>
                </TitledBlock>
            </div>
        )
    }

    function autopayOff() {
        return (
            <div>
                <TitledBlock title='Autopay Setup'>
                    <Row show={true} style={{ justifyContent: 'space-between' }}>
                        <div style={{ paddingRight: 10 }}>
                            {dueDate && <DueDateText>Due
                                {(() => {
                                    return days > 0 ? ' in ' + days + ' days' : null
                                })()}
                                {' '}as of {dueDate}</DueDateText>}
                            <BigGreenText>{balance}</BigGreenText>

                        </div>
                        <div>
                            <NewAccountLink to={`/new_bank_account`}>
                                <NewAccountButton>Add Bank Account</NewAccountButton>
                            </NewAccountLink>
                        </div>
                    </Row>
                </TitledBlock>

                <Row show={accountsLoaded}>
                    <FormikProvider value={formik}>

                        <FormBlock onSubmit={formik.handleSubmit}>

                            {error && <AlertMesssage type={'error'} message={error}></AlertMesssage>}

                            <FormItem show={!createAccount}>
                                <Title>Select Bank Account *</Title>
                                <Field name="bank_account" as="select" style={{ width: "100%", height: "30px", margin: "10px 0 25px", borderColor: "#ABB2B9" }}>
                                    {accountList}
                                </Field>
                            </FormItem>

                            <FormItem show={!createAccount}>
                                <Title>End Date *</Title>
                                <Field name="end_date" as="select" style={{ width: "100%", height: "30px", margin: "10px 0 25px", borderColor: "#ABB2B9" }}>
                                    <option key={'lease_end'} value={'lease_end'}>Lease End Date</option>
                                    <option key={'cancel'} value={'cancel'}>Until Cancelled</option>
                                </Field>
                            </FormItem>

                            <FormItem show={true}>
                                <Title>Payment Amount *</Title>
                            </FormItem>

                            <FormItem show={true}>
                                <RadioGroup>
                                    <RadioButton>
                                        <RadioInput
                                            type="radio"
                                            id="total"
                                            name="amount-type"
                                            value="total"
                                            defaultChecked
                                            onChange={onRadioChange}
                                        />
                                        <RadioLabel htmlFor="total">Monthly Payment (${currentUserState.currentUser && currentUserState.currentUser.activelease ? Math.round(currentUserState.currentUser.activelease.amount*100)/100 : '...'})</RadioLabel>
                                    </RadioButton>
                                    <RadioButton>
                                        <RadioInput
                                            type="radio"
                                            id="custom"
                                            name="amount-type"
                                            value="custom"
                                            onChange={onRadioChange}
                                        />
                                        <RadioLabel htmlFor="custom">Custom Amount</RadioLabel>
                                    </RadioButton>
                                </RadioGroup>
                            </FormItem>

                            <FormItem show={!isTotal}>
                                <Title>Enter Amount *</Title>
                                <TitleInput id="amount" type="text" error={formik.touched.amount && formik.errors.amount} {...formik.getFieldProps('amount')} />
                                <ErrorMessage>{formik.touched.amount && formik.errors.amount ? formik.errors.amount : null}</ErrorMessage>
                            </FormItem>

                            <SubmitButtonWrap newAccount={createAccount}>
                                {createAccount ?
                                    <FormButton type="button" active={true} onClick={() => history.push(`/new_bank_account`)}>Next</FormButton>
                                    :
                                    <FormButton type="submit" active={!isSubmitting} disabled={isSubmitting}>Set Autopay</FormButton>
                                }
                                <CancelButton type="button" onClick={() => history.push(`/`)}>Cancel</CancelButton>
                            </SubmitButtonWrap>

                        </FormBlock>

                    </FormikProvider>
                </Row>
            </div>
        );
    }

    return (
        <Page title='Autopay' tabNavigation={true}>
            {currentUserState.currentUser && currentUserState.currentUser.activelease && currentUserState.currentUser.activelease.restrict_payments == "1" && <Redirect to={"/"} />}
            {autopayInfo && autopayInfo!=null && autopayOn()}
            {autopayInfo == false && autopayOff()}
        </Page>
    );
}

const validate = (values: any) => {

    const errors: any = {};

    if (!values.amount) errors.amount = 'Required';
    else if (!/^\s*-?[1-9]\d*(\.\d{1,2})?\s*$/.test(values.amount)) errors.amount = 'Must contain valid amount';

    return errors;
};

export default AutopayPage;