import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Row = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: start;

  ${(props: { show: boolean }) => css`
  display: ${props.show ? "flex" : "none"};
`}
`;

export const NoticeText = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: #abadae;
`;

export const FormBlock = styled.form`
  width: 40%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0 40px;
    margin-top: 35px;
    width: 100%;
  }
`;
export const FormItem = styled.div`
  width: 100%;
  position: relative;

  ${(props: { show: boolean }) => css`
  display: ${props.show ? "block" : "none"};
`}
`;

export const Title = styled.div`
  color: #000000;
  font-size: 13px;
  font-weight: 700;
`;

export const TitleInput = styled.input`
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 25px;
  width: 100%;
  border-color: rgba(0,0,0,0);
  color: #000;
  font-size: 16px;
  box-sizing: border-box;

  ${(props: { error?: any }) => css`
  border-bottom: ${props.error ? "2px solid #D8000C" : "2px solid #EAECEE"};
  `}
`;

export const ErrorMessage = styled.div`
  position: absolute;
  bottom: 5px;
  font-size: 12px;
  color: #D8000C;
`;

export const SubmitButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;

  ${(props: { newAccount: boolean }) => css`
  margin-top: ${props.newAccount ? "30px" : "50px"};
`}
`;

export const CancelButton = styled.button`
  box-sizing: border-box;
  padding: 10px 20px;
  margin-left: 12px;
  color: #606060;
  background-color: rgb(235, 235, 235, 1);
  border-radius: 3px;
  border-width: 0;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: rgb(200, 200, 200, 1);
  }
`;

export const BigGreenText = styled.div`
  margin-top: 7px;
  color: #509c5b;
  font-weight: 700;
  line-height: 24px;
  font-size: 21px;
`;

export const DueDateText = styled.div`
  font-style: italic;
  font-weight: 300;
  color: #a7a8aa;
`;

export const NewAccountButton = styled.div`
  border-width: 0;
  background-color: #4bb43e;
  padding: 10px;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 700;
  color: white;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover {
    background-color: #419c35;
  }
`;

export const NewAccountLink = styled(Link)`
  text-decoration: none;
`;

export const RadioGroup = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: 14px;
`;

export const RadioButton = styled.div`
  display: flex;
  margin-bottom: 7px;

  &:hover {
    font-weight: 600;
  }
`;

export const RadioInput = styled.input`
  cursor: pointer;
`;

export const RadioLabel = styled.label`
  margin-left: 5px;
  cursor: pointer;
`;