import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Page } from '../page';
import { TitledBlock } from '../../components/blocks/titledBlock/titledBlock';
import {
  CheckIconWrap, CheckIcon, ConfirmationText, TicketText, TicketNumber,
  TrackText, DashboardLink, ContactText, ContactLink
} from './paymentConfirmation.styles';

const PaymentConfirmationPage = (props: any) => {

  const history = useHistory();
  if (!props.location.state) history.push(`/`);

  return (
    <Page title='New Ticket' tabNavigation={true}>
      <TitledBlock title='' emptyTitle>
        <CheckIconWrap>
          <CheckIcon icon={require('../../../assets/icons/check.svg')} />
        </CheckIconWrap>
        <ConfirmationText>Your payment has been submitted!</ConfirmationText>
        <TicketText>The reference number for your payment is</TicketText>
        <TicketNumber>{props.location.state.reference}</TicketNumber>
        <TrackText>You can track the progress in your <DashboardLink to='/'>Dashboard</DashboardLink></TrackText>
      </TitledBlock>
      <ContactText>We will contact you by <ContactLink to='/settings'>text</ContactLink> on <ContactLink to='/settings'>646-235-4547</ContactLink>
      </ContactText>
      <ContactText>Perfer a different contact method? <ContactLink to='/settings'>Update</ContactLink> your preferences</ContactText>
    </Page>
  );
}

export default PaymentConfirmationPage;
