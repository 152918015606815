import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const SummaryText = styled.span`
  font-size: 13px;
  color: rgb(171, 173, 174);
  font-weight: 700;
`;

export const SummaryNumber = styled.span`
  font-size: 18px;
  color: #f94247;
`;

export const PlusButtonWrap = styled.div`
  width: 100%;
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const PlusButton = styled(Link)`
  width: 40px;
  height: 35px;
  border-width: 0;

  cursor: pointer;

  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #4bb43e;

  border-radius: 4px;

  ${(props: {icon: string}) => css`
    background-image: url("${props.icon}");
  `}

  &:hover {
    background-color: #419c35;
  }
`;
