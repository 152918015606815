import * as React from 'react';
import { useEffect, useState } from 'react';
import useFetch from '../../hooks/useFetch'
import { Row } from "../../components/blocks/ticketBlock/ticketBlock.styles";
import { HeaderText, TransactionAmount, Transactions, TransactionText } from "./home.styles";

const TransactionsBlock = (props: any) => {

  const [{ responses }, doFetch] = useFetch('transactions');
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    doFetch({ method: 'get' });
  }, [])

  useEffect(() => {
    if (!responses) return
    setTransactions(responses.transactions);
  }, [responses])

  return (
    <Transactions>
      <Row>
        <HeaderText single indent>Date</HeaderText>
        <HeaderText indent>Description</HeaderText>
        <HeaderText single>Amount</HeaderText>
      </Row>
      {transactions.map((t, i) => {
        return (
          <Row key={i}>
            <TransactionText single>{t.date}</TransactionText>
            <TransactionText>{t.memo}</TransactionText>
            <TransactionAmount single negative={t.amount.charAt(0) == '-' ? true : false}>{t.amount}</TransactionAmount>
          </Row>
        )
      })}
    </Transactions>
  )
}

export default TransactionsBlock;