import * as React from 'react';
import {FC, useContext, useEffect, useState} from 'react';
import { TextInput } from '../../components/inputs/textInput/textInput';
import { Page } from '../page';
import { Button } from '../../components/buttons/button';
import useFetch from '../../hooks/useFetch'
import useLocalStorage from '../../hooks/useLocalStorage'
import { PageWrapper, LoginWrapper, InputSeparator, NameWrapper, Name, LoginLink } from './login.styles';
import {AlertMesssage} from "../../components/blocks/alertMessage/alertMessage";
import {CurrentUserContext} from "../../contexts/currentUser";
import {Redirect} from "react-router";
import {Link} from "react-router-dom";
import {
    FormikCheckbox,
    FormikInput,
    FormikPhoneField,
    FormikTextField,
    FormWrapper
} from "../../components/formik/formik.styles";
import {Field, Form, Formik} from "formik";
import {TitledBlock} from "../../components/blocks/titledBlock/titledBlock";
import {NoticeText} from "../newBankAccount/newBankAccount.styles";

interface Props {
  token: string;
}

export const RegisterPage : FC<Props> = (props):JSX.Element =>  {
    const [email, setEmail] = useState<string>('Loading...')
    const [name, setName] = useState<string>('Loading...')
    const [redirect, setRedirect] = useState<any>(null)
    const [registerObj, doRegister] = useFetch('register');
    const [userObj, doUser] = useFetch('reguser');
    const [, setRegToken] = useLocalStorage('regtoken')

    const handleRegister = (values:any) => {
        const token = props.token
        values.token = token;
        doRegister({
          method: 'post',
          data: values
        })
    }

    useEffect(() => {
        setRegToken(props.token)
        const token = props.token
        const user = {token}
        doUser({
          method: 'post',
          data: { user }
        })
    }, [])

    useEffect(() => {
        if (userObj.responses) {
          setName(userObj.responses.user.first_name + " " + userObj.responses.user.last_name);
          setEmail(userObj.responses.user.email);
        }
    }, [userObj])

    const validate = (values: any) => {
        const errors: any = {};

        if (values.password1 != values.password2) errors.password2 = 'Passwords don\'t match';
        else if (values.password1.length < 8) errors.password1 = 'Must be at least 8 symbols';

        return errors;
    };

  return (
        <Page title='Sign Up' tabNavigation={false} checkAuth={false}>
          {redirect}
              <TitledBlock title='Register new account'>
                  <NoticeText>
                      <NameWrapper>Name: <Name>{name}</Name></NameWrapper>
                      <NameWrapper>Email: <Name>{email}</Name></NameWrapper>
                      Create the password for your new account.
                  </NoticeText>
              </TitledBlock>
              {(userObj.errors) && <AlertMesssage type={userObj.errors ? 'error' : 'success'} message={userObj.errors.message}></AlertMesssage>}
              {(registerObj.errors || registerObj.responses) && <AlertMesssage type={registerObj.errors ? 'error' : 'success'} message={registerObj.errors ? registerObj.errors.message : registerObj.responses.message}></AlertMesssage>}
              {((!registerObj.responses || registerObj.responses.status !==1) && !userObj.errors) && getFields()}
              {(registerObj.responses && registerObj.responses.status ==1 || userObj.errors) && <LoginLink to="/login">Login</LoginLink>}
        </Page>
      );
  function getFields() {
    return(
        <FormWrapper>
            <br/>
            <Formik enableReinitialize={true} initialValues={{'password1': '', 'password2' : ''}} onSubmit={handleRegister} validate={validate}>
                {({ isSubmitting }) => (
                    <Form translate="yes">
                        <Field
                            name="password1"
                            type="password"
                            label="Choose password"
                            component={FormikTextField}
                        />
                        <Field
                            name="password2"
                            type="password"
                            label="Confirm password"
                            component={FormikTextField}
                        />
                        <br/>
                        <Button type="submit" disabled={isSubmitting}>Create Account</Button>
                    </Form>
                )}
            </Formik>
        </FormWrapper>
    );
  }
}
export default RegisterPage;