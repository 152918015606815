import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

export const Wrap = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 8px 8px 10px 8px;
  box-sizing: border-box;

  ${(props: {isMe: boolean}) => {
    return props.isMe ?
      css`
        margin-left: 20%;
        background-color: rgb(246, 239, 247);
        border-radius: 4px 0 0 4px;
      ` :
      css`
        margin-right: 20%;
        background-color: rgb(231, 245, 220);
        border-radius: 0 4px 4px 0;
      `;
  }}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 10px;
  font-weight: 700;
  margin-bottom: 6px;

  ${(props: {isMe: boolean}) => {
    return props.isMe ?
      css`
        color: rgb(160, 86, 162);
      ` :
      css`
        color: rgb(114, 190, 88);
      `;
  }}
`;

export const Author = styled.div`
  flex: 3;
`;

export const MessageDate = styled.div`
  flex: 1;
`;

export const Message = styled.div`
  color: rgb(171, 173, 174);
  font-size: 12px;
`;
