import * as React from 'react';
import { Page } from '../page';
import { TitledBlock } from '../../components/blocks/titledBlock/titledBlock';
import { Row } from '../../components/blocks/ticketBlock/ticketBlock.styles';
import { PayButton, PayLink, AutopayButton, BoldText, BigGreenText, DueDateText, Transactions, HeaderText, TransactionText, TransactionAmount } from './home.styles';
import useFetch from "../../hooks/useFetch";
import { useContext, useEffect, useState } from "react";
import TransactionsBlock from "./transactions";
import { CurrentUserContext } from "../../contexts/currentUser";
import { useHistory } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import { setTimeout } from 'timers/promises';

const HomePage = () => {

  const [rent, setRent] = useState<string>('Loading...');
  const [dueDate, setDueDate] = useState<string>('Loading...');
  const [endDate, setEndDate] = useState<string>('Loading...');
  const [days, setDays] = useState<number>(null);
  const [tokenUpdate, seTokenUpdate] = useState<boolean>(false);
  const [balance, setBalance] = useState<string>('Loading...');
  const [{ responses }, doFetch] = useFetch('balance');
  const [leaseResponse, changeLease] = useFetch('changeLease');
  const [currentUserState] = useContext(CurrentUserContext);
  const [token, setToken] = useLocalStorage('token')

  const handleLeaseChange = (e: any) => {
    changeLease({ method: 'post', data: { 'lease_id': e.target.value } });
  }

  useEffect(() => {
    doFetch({ method: 'get' })
  }, [])

  useEffect(() => {
    if (!responses) return
    setBalance(responses.balance);
    setRent(responses.rent);
    setDays(responses.days);
    setEndDate(responses.enddate);
    setDueDate(responses.duedate);
  }, [responses]);

  useEffect(() => {
    if (!leaseResponse.responses) return;
    setToken(leaseResponse.responses.token);
    seTokenUpdate(true);
  }, [leaseResponse.responses])

  useEffect(() => {
    if (!tokenUpdate) return;
    window.location.reload();
  }, [tokenUpdate])

  function getLeasesSelect() {
    const activeLease = currentUserState.currentUser.activelease.id;
    return (
      <TitledBlock title='Select Lease'>
        <select onChange={e => handleLeaseChange(e)} value={activeLease}>
          {currentUserState.currentUser.leases.map((lease: any) => {
            return (
              <option value={lease.id}>{lease.name}</option>
            )
          })}
        </select>
      </TitledBlock>
    )
  };

  return (
    <Page title='Home' tabNavigation={true}>
      <TitledBlock title='Account Summary'>
        <Row style={{ justifyContent: 'space-between' }}>
          <div style={{ paddingRight: 10, textAlign: 'left' }}>
            <BoldText>Your current balance is</BoldText>
            <BigGreenText>{balance}</BigGreenText>
            {dueDate && <DueDateText>Due
              {(() => {
                return days > 0 ? ' in ' + days + ' days' : null
              })()}
              {' '}({dueDate})</DueDateText>}
          </div>
          {currentUserState.currentUser && currentUserState.currentUser.activelease && currentUserState.currentUser.activelease.restrict_payments != "1" &&
            <div>
              <PayLink to={`/payment`}>
                <PayButton>Pay Now</PayButton>
              </PayLink>
              <PayLink to={`/autopay`}>
                <AutopayButton>Set Up Autopay</AutopayButton>
              </PayLink>
            </div>
          }
        </Row>
      </TitledBlock>
      <TitledBlock title='Lease Summary'>
        <Row>
          <BoldText style={{ flex: 2 }}>Your lease expires on: </BoldText><BigGreenText>{endDate}</BigGreenText>
        </Row>
        <Row>
          <BoldText style={{ flex: 2 }}>Your monthly rent is: </BoldText><BigGreenText>{rent}</BigGreenText>
        </Row>
      </TitledBlock>
      {currentUserState.currentUser && currentUserState.currentUser.leases && currentUserState.currentUser.leases.length > 1 && getLeasesSelect()}
      <TitledBlock title='Transaction History'>
        <TransactionsBlock />
      </TitledBlock>
    </Page>
  );
}

export default HomePage;