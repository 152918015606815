import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
`;

export const Wrapper = styled.div`
  background: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
`;

export const Title = styled.div`
  font-size: 16px;
  color: #5E9BEC;
  text-align: left;
  border-bottom: ${(p: {emptyTitle?: boolean}) => p.emptyTitle ? 'none' : '1px solid #2b7ce7'};
  font-weight: 700;
  font-size: 15px;
  box-sizing: border-box;
  padding: 0 0 4px 0;
`;

export const Content = styled.div`
  box-sizing: border-box;
  padding: 8px 0;
`;
