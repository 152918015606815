export const ticketColors = (ticket: MaintenanceTicket): {mainColor: string; status: string; lineColor: string; bgColor: string; } => {
  let mainColor = 'gray';
  let lineColor = 'gray';
  let status = '';
  let bgColor = '#ebebeb';

  if (ticket) {
    switch (ticket.status) {
      case 'waitingForUser':
        mainColor = '#c65844';
        lineColor = 'red';
        status = 'Action needed';
        bgColor = '#fcf3f4';
        break;
      case 'received':
        mainColor = '#aba012';
        lineColor = '#f2cb28';
        status = 'Received';
        bgColor = '#fcfce2';
        break;
      case 'completed':
        mainColor = '#70c431';
        lineColor = 'green';
        status = 'Completed';
        bgColor = '#e6f4db';
        break;
    }
  }

  return {mainColor, status, lineColor, bgColor};
};
