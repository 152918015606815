import * as React from 'react';
import * as dateformat from 'dateformat';
import { Container, Wrap, Header, Author, MessageDate, Message } from './chatMessage.style';

interface Props {
  isMe: boolean;
  author: string;
  date: string;
  text: string;
}

export class ChatMesssage extends React.Component<Props, {}> {
  public render() {
    const timeString =  dateformat(new Date(this.props.date), 'mm/dd/yyyy');

    return (
      <Container>
        <Wrap isMe={this.props.isMe}>
          <Header isMe={this.props.isMe}>
            <Author>{this.props.author}</Author>
            <MessageDate>{timeString}</MessageDate>
          </Header>
          <Message>
              {this.props.text}
          </Message>
        </Wrap>
      </Container>
    );
  }
}
