import * as React from 'react';
import { Container, LogoLink, Logo, Title, TitleWrap } from './header.styles';
import { FC, useContext } from "react";
import { CurrentUserContext } from "../../../contexts/currentUser";
import { LogoutLink } from '../../../pages/login/login.styles';

interface Props {
  userName: string;
}

export const Header: FC<Props> = (props): JSX.Element => {

  const [currentUserState] = useContext(CurrentUserContext);

  return (
    <Container background={require('../../../../assets/img/header.jpg')}>
      <LogoLink to={'/'}>
        <Logo src={require('../../../../assets/img/logo.png')}></Logo>
      </LogoLink>
      <TitleWrap>
        {currentUserState.currentUser ? <Title>Welcome, {currentUserState.currentUser.first_name + ' ' + currentUserState.currentUser.last_name}!<LogoutLink to='/logout'>Log out</LogoutLink></Title> : <Title>Welcome to Resident Portal</Title>}
      </TitleWrap>
    </Container>
  );
}
