import * as React from 'react';
import * as dateformat from 'dateformat';
import { Container, Message } from './alertMessage.style';

interface Props {
  visible: boolean
  type: string;
  message: string;
}

export class AlertMesssage extends React.Component<Props, {}> {
    public static defaultProps = {
        visible: false,
        type: 'error',
        message: ''
    };
  public render() {
    return (
      <Container type={this.props.type} visible={this.props.message != ''}>
          <Message>
              {this.props.message}
          </Message>
      </Container>
    );
  }
}
