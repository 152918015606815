import { useState, useEffect } from 'react'

export default (key: string, initialValue:string = '') => {

    const [value, setValue] = useState<string>(() => {
        return localStorage.getItem(key) as string || initialValue
    })

    useEffect(() => {
        localStorage.setItem(key, value)
    }, [key, value])

    return [value, setValue] as const
}