import * as React from 'react';
import { Page } from '../page';
import {TitledBlock} from '../../components/blocks/titledBlock/titledBlock';
import { SummaryText, SummaryNumber, PlusButtonWrap, PlusButton } from './maintenance.styles';
import { TicketBlock } from '../../components/blocks/ticketBlock/ticketBlock';
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import useFetch from "../../hooks/useFetch";



export const MaintenancePage = () => {
  const [openTicketsCount, setOpenTicketsCount] = useState<number>(0)
  const [tickets, setTickets] = React.useState<Array<any>>([])
  const [{responses, errors, isLoading}, doFetch] = useFetch('getTickets')

  useEffect(() => {
    doFetch({
      method: 'get',
    })
  }, [])
  useEffect(() => {
    if (!responses) return
    setTickets(responses.tickets)
    setOpenTicketsCount(responses.count)
  }, [responses])
  function test() {
    // here we will be getting all necessary data
    this.setState({
      openTicketsCount: 3,
      tickets: [
        {
          title: 'Broken Steam - Bathroom',
          number: 1254755,
          timestamp: 1557832486000,
          status: 'waitingForUser',
          description: 'The steam is leaking and it doesnt work at all and e' +
                       'The steam is leaking and it doesnt work at The steam '
        },
        {
          title: 'Broken Steam - Bathroom',
          number: 1254756,
          timestamp: 1557832486000,
          status: 'received',
          description: 'The steam is leaking and it doesnt work at all and e' +
                       'The steam is leaking and it doesnt work at The steam '
        },
        {
          title: 'Broken Steam - Bathroom',
          number: 1254757,
          timestamp: 1557832486000,
          status: 'completed',
          description: 'The steam is leaking and it doesnt work at all and e' +
                       'The steam is leaking and it doesnt work at The steam '
        },
        {
          title: 'Broken Steam - Bathroom',
          number: 1254758,
          timestamp: 1557832486000,
          status: 'completed',
          description: 'The steam is leaking and it doesnt work at all and e' +
                       'The steam is leaking and it doesnt work at The steam '
        },
        {
          title: 'Broken Steam - Bathroom',
          number: 1254759,
          timestamp: 1557832486000,
          status: 'completed',
          description: 'The steam is leaking and it doesnt work at all and e' +
                       'The steam is leaking and it doesnt work at The steam '
        }
      ]
    });
  }

  return (
    <Page title='Maintenance' tabNavigation={true}>
      <TitledBlock title='Maintenance Summary'>
        <SummaryText>
          You have <SummaryNumber>{openTicketsCount}</SummaryNumber> open maintenance tickets
        </SummaryText>
      </TitledBlock>
      {tickets.map((ticket) => <TicketBlock ticket={ticket} key={ticket.number} />)}
      <PlusButtonWrap>
        <PlusButton  icon={require('../../../assets/icons/plus.svg')} to={'/new_ticket'} />
      </PlusButtonWrap>
    </Page>
  );
}
