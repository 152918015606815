import styled from 'styled-components';

export const PageContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;    
`;

export const PageWrap = styled.div`
  width: 100%;
  height: 100%;
  margin:auto;
  overflow-y: scroll;
  & > div { max-width:1200px; margin:auto; }
`;

export const PageContent = styled.div`
  width: 100%;
  position: relative;
  top: -25px;  
`;
