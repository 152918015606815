import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 44px;
  min-height: 44px;
  width: '100%';
  background: #6a8b9c;
  flex-direction: row;
`;
