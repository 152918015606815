import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import { Page } from '../page';
import { Button } from '../../components/buttons/button';
import useFetch from '../../hooks/useFetch'
import useLocalStorage from '../../hooks/useLocalStorage'
import { PageWrapper, LoginWrapper, InputSeparator, ForgotLink } from './login.styles';
import {AlertMesssage} from "../../components/blocks/alertMessage/alertMessage";
import {CurrentUserContext} from "../../contexts/currentUser";
import {Redirect} from "react-router";
import {FormikInput} from "../../components/formik/formik.styles";


const LoginPage = () => {
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [redirect, setRedirect] = useState<any>(null)
  const [{responses, errors, isLoading}, doFetch] = useFetch('login');
  const [, setToken] = useLocalStorage('token')
  const [, dispatch] = useContext(CurrentUserContext)

  const handleLogin = () => {
    const user = { username, password }
    doFetch({
      method: 'post',
      data: { user }
    })
  }

  useEffect(() => {
    if (!responses) return
    setToken(responses.token)
    dispatch({ type: 'SET_AUTHORIZED', payload: responses.user || null})
    setRedirect(<Redirect to={'/'}/>);
  }, [responses, setToken])

  const onKeyDownHandler = (e: { keyCode: number; }) => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  };

  return (
        <Page title='Simplicity Login' tabNavigation={false} checkAuth={false}>
          {redirect}
          <PageWrapper>
            <LoginWrapper>
              {(errors || responses) && <AlertMesssage type={errors ? 'error' : 'success'} message={errors ? errors.message : responses.message}></AlertMesssage>}
              <FormikInput
                placeholder='Login'
                value={username}
                onChange={e => setUsername(e.target.value)}
                onKeyDown={onKeyDownHandler}
              />
              <InputSeparator />
              <FormikInput
                placeholder='Password'
                type='password'
                value={password}
                onChange={e => setPassword(e.target.value)}
                onKeyDown={onKeyDownHandler}
              />
              <InputSeparator />
              <Button onClick={handleLogin}>
                Login
              </Button>
              <ForgotLink to="/forgot">Forgot Password?</ForgotLink>
            </LoginWrapper>
          </PageWrapper>
        </Page>
      );
}
export default LoginPage;