import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const TabLink = styled(Link)`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: rgba(0,0,0,0.1);
  }
`;

export const TabIcon = styled.div`
  width: 24px;
  height: 24px;
  background-size: contain;

  ${(props: {icon: string}) => css`
    background-image: url("${props.icon}");
  `}
`;
