import * as React from 'react';
import { useState, useEffect, useContext } from "react";
import useFetch from "../../hooks/useFetch";
import { useHistory } from 'react-router-dom';
import { CurrentUserContext } from "../../contexts/currentUser";
import { Page } from '../page';
import { FormButton } from '../../components/buttons/button';
import { TitledBlock } from '../../components/blocks/titledBlock/titledBlock';
import { AlertMesssage } from "../../components/blocks/alertMessage/alertMessage";
import {
  Row, FormBlock, FormItem, Title, TitleInput, ErrorMessage, SubmitButtonWrap, CancelButton,
  BigGreenText, DueDateText, NewAccountLink, NewAccountButton, RadioGroup, RadioButton,
  RadioInput, RadioLabel
} from './payment.styles';
import { useFormik, Field, FormikProvider } from 'formik';
import {Redirect} from "react-router";

const PaymentPage = () => {

  const [dueDate, setDueDate] = useState<string>('Loading...');
  const [days, setDays] = useState<number>(null);
  const [balance, setBalance] = useState<any>('Loading...');
  const [leaseId, setLeaseId] = useState<number>(null);
  const [accounts, setAccounts] = useState<any>([]);
  const [reference, setReference] = useState(null);
  const [error, setError] = useState<any>(null);
  const [redirect, setRedirect] = useState<boolean>(false);
  const [isTotal, setIsTotal] = useState<boolean>(true);
  const [createAccount, setCreateAccount] = useState<boolean>(false);
  const [accountsLoaded, setAccountsLoaded] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [{ responses }, doFetch] = useFetch('balance');
  const [accountsResult, fetchAccounts] = useFetch('getBankAccounts');
  const [paymentResult, sendPaymentData] = useFetch('sendPayment');
  const [tokenResult, updateToken] = useFetch('updateToken');
  const [removeAccountResult, removeAccount] = useFetch('removeAccount');
  const history = useHistory();
  const [currentUserState] = useContext(CurrentUserContext);

  // Formik declaration & submission
  const formik = useFormik({
    initialValues: { amount: '', bank_account: '' },
    validate,
    onSubmit: values => {
      setIsSubmitting(true);
      sendPaymentData({ method: 'POST', data: { ...values, lease_id: leaseId } });
    }
  });

  useEffect(() => {
    doFetch({ method: 'get' })
    fetchAccounts({ method: 'get' })
  }, []);

  useEffect(() => {
    if (!responses) return;
    setBalance(responses.balance);
    setDays(responses.days);
    setDueDate(responses.duedate);
    setLeaseId(responses.lease_id);
    formik.setFieldValue('amount', parseFloat(responses.balance.replace('$', '').replaceAll(',', '')));
  }, [responses]);

  /*useEffect(() => {
    if (!paymentResult.responses) return;

    const result = paymentResult.responses;
    const { xResult, xRefNum, xToken, xError, sut } = result;
    console.log(result);

    if (xResult === "A") {
      setReference(xRefNum);
      const tokenData = { token: xToken, bank_account: formik.values.bank_account }
      updateToken({ method: 'POST', data: tokenData });
    }

    if (xResult === "E") {
      if (sut) {
        setError("Something went wrong. Please re-enter Bank Account");
        setRedirect(true);
        removeAccount({ method: 'POST', data: { bank_account: formik.values.bank_account } });
      }
      else setError(xError);
      setIsSubmitting(false);
    }
  }, [paymentResult.responses]);*/

  useEffect(() => {
    if (!paymentResult.responses) return;

    const result = paymentResult.responses;
    const { Result, RefNum, GatewayStatus, Error, sut} = result;
    console.log(result);

    if (Result === "S" && GatewayStatus == "Approved") {
      setReference(result.RefNum);
      setIsSubmitting(false);
      history.push({
        pathname: '/payment_confirmation',
        state: {
          reference: result.RefNum
        },
      });
    }

    if (Result === "E" || GatewayStatus != "Approved") {
      if (sut) {
        setError("Something went wrong. Please re-enter Bank Account");
        setRedirect(true);
        removeAccount({ method: 'POST', data: { bank_account: formik.values.bank_account } });
      }
      else setError(Error);
      setIsSubmitting(false);
    }
  }, [paymentResult.responses]);

  useEffect(() => {
    if (!tokenResult.responses) return;
    setIsSubmitting(false);
    history.push({
      pathname: '/payment_confirmation',
      state: {
        reference: reference
      },
    });
  }, [tokenResult.responses]);

  useEffect(() => {
    if (!removeAccountResult.responses) return;
    console.log(removeAccountResult.responses);
  }, [removeAccountResult.responses]);

  useEffect(() => {
    if (!accountsResult.responses) return;
    setAccountsLoaded(true);
    if (!accountsResult.responses.count) {
      setCreateAccount(true);
      return;
    }
    setAccounts(accountsResult.responses.accounts);
    formik.setFieldValue('bank_account', accountsResult.responses.accounts[0].id);
  }, [accountsResult.responses]);

  const onRadioChange = (e: any) => {
    e.persist();
    if (e.target.value === "total")
      formik.setFieldValue('amount', parseFloat(balance.replace('$', '').replaceAll(',', '')));
    else if (e.target.value === "custom") formik.setFieldValue('amount', '', false);

    setIsTotal(!isTotal);
  };

  const accountList: any = accounts.map((acc: any) => {
    return <option key={acc.id} value={acc.id}>{acc.nickname} ***{acc.account}</option>
  });

  const handleReenter = () => {
    setError(null);
    if (redirect) history.push(`/new_bank_account`)
  };

  return (
    <Page title='Payments' tabNavigation={true}>
      {currentUserState.currentUser && currentUserState.currentUser.activelease && currentUserState.currentUser.activelease.restrict_payments == "1" && <Redirect to={"/"}/> }
      <div onClick={handleReenter}>
        <TitledBlock title='One-Time Payment'>
          <Row show={true} style={{ justifyContent: 'space-between' }}>
            <div style={{ paddingRight: 10 }}>
              {dueDate && <DueDateText>Due
                {(() => {
                  return days > 0 ? ' in ' + days + ' days' : null
                })()}
                {' '}as of {dueDate}</DueDateText>}
              <BigGreenText>{balance}</BigGreenText>

            </div>
            <div>
              <NewAccountLink to={`/new_bank_account`}>
                <NewAccountButton>Add Bank Account</NewAccountButton>
              </NewAccountLink>
            </div>
          </Row>
        </TitledBlock>

        <Row show={accountsLoaded}>
          <FormikProvider value={formik}>

            <FormBlock onSubmit={formik.handleSubmit}>

              {error && <AlertMesssage type={'error'} message={error}></AlertMesssage>}

              <FormItem show={true}>
                <Title>Payment Amount *</Title>
              </FormItem>

              <FormItem show={true}>
                <RadioGroup>
                  <RadioButton>
                    <RadioInput
                      type="radio"
                      id="total"
                      name="amount-type"
                      value="total"
                      defaultChecked
                      onChange={onRadioChange}
                    />
                    <RadioLabel htmlFor="total">Total Balance ({balance})</RadioLabel>
                  </RadioButton>
                  <RadioButton>
                    <RadioInput
                      type="radio"
                      id="custom"
                      name="amount-type"
                      value="custom"
                      onChange={onRadioChange}
                    />
                    <RadioLabel htmlFor="custom">Custom Amount</RadioLabel>
                  </RadioButton>
                </RadioGroup>
              </FormItem>

              <FormItem show={!isTotal}>
                <Title>Enter Amount *</Title>
                <TitleInput id="amount" type="text" error={formik.touched.amount && formik.errors.amount} {...formik.getFieldProps('amount')} />
                <ErrorMessage>{formik.touched.amount && formik.errors.amount ? formik.errors.amount : null}</ErrorMessage>
              </FormItem>

              <FormItem show={!createAccount}>
                <Title>Select Bank Account *</Title>
                <Field name="bank_account" as="select" style={{ width: "100%", height: "30px", marginTop: "10px", borderColor: "#ABB2B9" }}>
                  {accountList}
                </Field>
              </FormItem>

              <SubmitButtonWrap newAccount={createAccount}>
                {createAccount ?
                  <FormButton type="button" active={true} onClick={() => history.push(`/new_bank_account`)}>Next</FormButton>
                  :
                  <FormButton type="submit" active={!isSubmitting} disabled={isSubmitting}>Pay Now</FormButton>
                }
                <CancelButton type="button" onClick={() => history.push(`/`)}>Cancel</CancelButton>
              </SubmitButtonWrap>

            </FormBlock>

          </FormikProvider>
        </Row>
      </div>
    </Page>
  );
};

const validate = (values: any) => {

  const errors: any = {};

  if (!values.amount) errors.amount = 'Required';
  else if (!/^\s*-?[1-9]\d*(\.\d{1,2})?\s*$/.test(values.amount)) errors.amount = 'Must contain valid amount';

  return errors;
};

export default PaymentPage;