export const iFieldStyleInit = {
    "padding": "5px",
    "width": "100%",
    "color": "#000",
    "border-color": "rgba(0,0,0,0)",
    "border-bottom": "2px solid #EAECEE",
    "font-size": "16px",
    "box-sizing": "border-box"
};

export const iFieldStyleError = {
    "padding": "5px",
    "width": "100%",
    "color": "#000",
    "border-color": "rgba(0,0,0,0)",
    "border-bottom": "2px solid #D8000C",
    "font-size": "16px",
    "box-sizing": "border-box"
};

export const iFrameStyle = {
    "width": "100%",
    "height": "50px",
    "marginTop": "5px",
    "marginBottom": "3px",
    "border": "0px solid black"
};

export const threeds = {
    enable3DS: false,
    waitForResponse: false,
    amount: 0,
    month: "01",
    year: "2020"
};