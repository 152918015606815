import styled, {css} from 'styled-components';
import {Field, useFormikContext} from "formik";
import * as React from "react";
import InputMask from "react-input-mask";
export const TextInput = styled.input`
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 3px;
  font-size: 14px;
`;

export const FLabel = styled.label`
    color: #000000;
    font-size: 13px;
    font-weight: 700;
    display: block;
`;

export const FBlock = styled.div`
    width:100%;
    max-width:400px;
    
`;

export const FormWrapper = styled.div`
    width:90%;
    max-width:400px;
    padding-left:20px;    
    margin: auto;
    margin-top: 50px;
`;

export const FormikInput = styled.input`
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 25px;
  width: 100%;
  border-color: rgba(0,0,0,0); 
  color: #000;
  font-size: 16px;
  box-sizing: border-box;

  ${(props: { error?: any }) => css`
  border-bottom: ${props.error ? "2px solid #D8000C" : "2px solid #EAECEE"};
  `}
`;

export const ErrorMessage = styled.div`
  color:red;
  margin-top: -25px;
  font-size: 12px;
  margin-left: 10px;
`;
export const FormikTextField = (props: any) => {
  const { label, form, field, ...rest } = props
  return (
      <FBlock>
        {label && <FLabel htmlFor={field.name}>{label}</FLabel>}
        <FormikInput id={field.name} {...field} {...rest} />
          {form.touched[field.name] &&
          form.errors[field.name] && <ErrorMessage className="error">{form.errors[field.name]}</ErrorMessage>}
      </FBlock>
  )
}

export const FormikPhoneField = (props:any) => {

    const { label, form, field, ...rest } = props
    const formikProps = useFormikContext()

    return (
        <FBlock>
            {label && <FLabel htmlFor={field.name}>{label}</FLabel>}

            <InputMask {...field}
                {...props} mask="+1(999)999-99-99" onChange={e => {
                 const value = e.target.value || '';
                formikProps.setFieldValue("phone", value)
             }}>{(inputProps: JSX.IntrinsicAttributes) => <FormikInput {...inputProps} />}</InputMask>

            {form.touched[field.name] &&
            form.errors[field.name] && <ErrorMessage className="error">{form.errors[field.name]}</ErrorMessage>}
        </FBlock>
    )
};

export const FormikPhoneField2 = (props: any) => {
    const { label, form, field, ...rest } = props
    return (
        <FBlock>
            {label && <FLabel htmlFor={field.name}>{label}</FLabel>}
            <InputMask {...props} onChange={e => {
                const value = e.target.value || '';
                console.log({ value });
                const formikProps = useFormikContext();
                formikProps.setFieldValue("phone", value)
            }} mask="+1 (___) ___-__-__" maskChar=" " />
            {form.touched[field.name] &&
            form.errors[field.name] && <ErrorMessage className="error">{form.errors[field.name]}</ErrorMessage>}
        </FBlock>
    )
}

export const FormikCheckbox = (props: any) => {
    const { label, form, field, ...rest } = props
    return (
        <FBlock>
            <FLabel>
                <Field style={{width: '12px', height: '12px', marginLeft: 0, marginTop: '10px'}} type="checkbox" checked={field.value} {...field} {...rest} />
                {label}
            </FLabel>
            {form.touched[field.name] &&
            form.errors[field.name] && <ErrorMessage className="error">{form.errors[field.name]}</ErrorMessage>}
        </FBlock>
    )
}
