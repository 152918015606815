import * as React from 'react';
import { TabLink, TabIcon } from './tab.styles';

export interface TabInterface {
  iconURL: string;
  href: string;
}

interface Props {
  data: TabInterface;
}

export class Tab extends React.Component<Props, {}> {
  public render() {
    return (
      <TabLink to={this.props.data.href}>
        <TabIcon icon={this.props.data.iconURL} />
      </TabLink>
    );
  }
}
