import * as React from 'react';
import { Page } from '../page';
import { TitledBlock } from '../../components/blocks/titledBlock/titledBlock';
import { CheckIconWrap, CheckIcon, ConfirmationText, TicketText, TicketNumber,
  TrackText, DashboardLink, ContactText, ContactLink } from './submitConfirmation.styles';
import {useHistory} from "react-router-dom";

const SubmitConfirmationPage = (props: any) => {

    const history = useHistory();
    if (!props.location.state) history.push(`/`);

    return (
      <Page title='New Ticket' tabNavigation={true}>
        <TitledBlock title='' emptyTitle>
          <CheckIconWrap>
            <CheckIcon  icon={require('../../../assets/icons/check.svg')} />
          </CheckIconWrap>
          <ConfirmationText>Your request has been submitted!</ConfirmationText>
          <TicketText>The ticket number for your request is</TicketText>
          <TicketNumber>{props.location.state.number}</TicketNumber>
          <TrackText>You can track the progress in your <DashboardLink to='/maintenance'>Mantenance Dashboard</DashboardLink></TrackText>
        </TitledBlock>
        <ContactText>We will contact you by <ContactLink to='/'>text</ContactLink> on <ContactLink to='/'>646-235-4547</ContactLink>
        </ContactText>
        <ContactText>Perfer a different contact method? <ContactLink to='/'>Update</ContactLink> your preferences</ContactText>
      </Page>
    );
}
export default SubmitConfirmationPage;