import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoginWrapper = styled.div`
  width: 100%;
  max-width:400px;
  box-sizing: border-box;
  margin-top:100px;

  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export const InputSeparator = styled.div`
  width: 100%;
  height: 10px;
`;

export const NameWrapper = styled.div`  
  margin-bottom:7px;
  font-size:14px;
  color:#000;
`;

export const Name = styled.div`
  display: inline-block;
  font-weight: 800;
`;

export const LoginLink = styled(Link)`
  box-sizing: border-box;
  padding: 10px 20px;
  color: white;
  background-color: rgba(38, 125, 224, 1);
  border-radius: 3px;
  border-width: 0;
  font-size: 14px;
  text-decoration: none;
  max-width: 100px;
  text-align: center;
  &:hover {
    background-color: rgba(18, 105, 204, 1);
  }
`;
export const ForgotLink = styled(Link)`
  margin: auto;
  font-size: 14px;
  margin-top: 5px;
`;

export const TextLine = styled.div`  
  color: #000000;
    font-size: 13px;
    font-weight: 700;
    display: block;
`;

export const LogoutLink = styled(Link)`  
    color: #426b95 !important;
    font-size: 13px;
    float: right;
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #ffffffc2;
    line-height: 26px;
    margin-top: -5px;
    padding: 0 15px;
    margin-right: -5px;
    border-radius: 0 13px 13px 0;
    display:none;
`;