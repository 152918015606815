import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const CheckIconWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const CheckIcon = styled.div`
  width: 100px;
  height: 100px;
  background-size: 100px 100px;
  background-repeat: no-repeat;
  background-size: contain;
  ${(props: {icon: string}) => css`
    background-image: url("${props.icon}");
  `}
`;

export const ConfirmationText = styled.div`
  margin: 10px 0 5px;
  color: #26c926;
  font-size: 17px;
  font-weight: 700;
  font-family: Segoe, "Segoe UI", Arial, Helvetica, sans-serif;
  text-align: center;
`;

export const TicketText = styled.div`
  color: #abadae;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
`;

export const TicketNumber = styled.div`
  margin: 20px 0;
  color: #5e9beb;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
`;

export const TrackText = styled.div`
  margin-bottom: 12px;
  color: #abadae;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
`;

export const DashboardLink = styled(Link)`
  color: #1185f9;
  &:hover {
    text-decoration: none;
  }
`;

export const ContactText = styled.div`
  margin: 15px 0;
  color: #1185f9;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
`;

export const ContactLink = styled(Link)`
  color: #1185f9;
  &:hover {
    text-decoration: none;
  }
`;