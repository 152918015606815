import styled, { css } from 'styled-components';

export const NoticeText = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: #abadae;
`;

export const RedText = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: red;
  text-align: center;
  padding:10px;
`;

export const FileError = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #000;
  font-weight: 800;
  background: #ffc2c2;
  padding: 10px;
`;

export const Title = styled.div`
  color: #000000;
  font-size: 13px;
  font-weight: 700;
  margin:10px 0;
`;

export const StageBlock = styled.div`
  width: 100%;  
  padding: 10px;
  box-sizing: border-box;  
`;

export const TypeWrap = styled.div`
  margin-top: 10px;
  width: 100%;
  position: 'relative';
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top:20px;
`;

export const TypeBlock = styled.label`
  width: 30%;
  height: 100px;
  background-color: rgb(244, 248, 249);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: #E6EEF0;
  }
`;

export const TypeIcon = styled.div`
  flex: 3;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 10px;

  ${(props: {icon: string}) => css`
    background-image: url("${props.icon}");
  `}
`;

export const TypeName = styled.div`
  height: 16px;
  text-align: center;
  font-size: 12px;
  width: 100%;
  font-weight: 400;
  color: #6a8b9c;
  text-transform: uppercase;

  white-space: nowrap;
`;

export const TitleInput = styled.input`
  margin-top: 5px;
  margin-bottom: 15px;
  width: 100%;
  border-width: 0;
  border-bottom: 2px solid #efefef;
  color: #000;
  font-size: 16px;
  font-family: inherit;
  &:hover {
    border-color: #009788;
  }
`;

export const DetailsTextArea = styled.textarea`
  margin-top: 5px;
  margin-bottom: 15px;
  width: 100%;
  border-width: 0;
  border-bottom: 2px solid #efefef;
  color: #000;
  font-size: 16px;
  font-family: inherit;
  &:hover {
    border-color: #009788;
  }
`;

export const SubmitButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:10px;
`;

export const PreviewsWrap = styled.div`
  margin-top: 10px;
  width: 100%;
  position: 'relative';
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;
export const PreviewsBlock = styled.div`
  width: 20%;
  background-color: rgb(244, 248, 249);
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
  &:hover {
    background-color: #E6EEF0;
  }
  & > img {width:100%}
  & > span {padding: 3px;
    padding-top: 0;
    position: absolute;
    top: 0;
    right: 0;
    background: #f00;
    border-radius: 10px;
    border: 2px solid #fff;
    color: #fff;
    font-weight: 800;
    line-height: 10px;
    font-size: 14px;
    cursor:pointer}
`;