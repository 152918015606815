import styled, {css} from 'styled-components';
import { Link } from 'react-router-dom';

export const TicketLink = styled(Link)`
  text-decoration: none;
`;

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  text-decoration: none;
`;

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;

  font-size: 11px;
  font-weight: 700;

  cursor: pointer;

  ${(props: {color: string}) => css`
    background-color: ${props.color};
  `}
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const Title = styled.div`
  flex: 2;

  ${(props: {color: string}) => css`
    color: ${props.color};
  `}
`;

export const TicketNo = styled(Title)`
  flex: 1
`;

export const TimeString = styled.div`
  font-size: 10px;
  color: rgba(88,82,82,.76);
  padding-right: 5px;
  position: relative;

  &:before {
    content: "|";
    display: block;
    position: absolute;
    right: -5px;
    top: 0;
    width: 10px;
    color: rgba(88,82,82,.76);
    text-align: center;
  }
`;

export const Status = styled.div`
  font-size: 10px;
  white-space: nowrap;
  padding: 0 5px;
  position: relative;

  ${(props: {color: string}) => css`
    color: ${props.color};
  `}

  &:before {
    content: "|";
    display: block;
    position: absolute;
    right: -5px;
    top: 0;
    width: 10px;
    color: rgba(88,82,82,.76);
    text-align: center;
  }
`;

export const Description = styled.div`
  padding-left: 5px;

  font-size: 10px;
  color: rgba(88,82,82,.76);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
