import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 5px 5px 30px 5px;
  background-size: cover;

  ${(props: { background: string }) => css`
    background-image: url("${props.background}");
  `}
`;

export const LogoLink = styled(Link)`
  display: block;
  width: 150px;
  text-decoration: none;
`;

export const Logo = styled.div`
  width: 137px;
  height: 40px;
  margin: 0 0 30px 10px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  ${(props: { src: string }) => css`
    background-image: url("${props.src}");
  `}
`;

export const TitleWrap = styled.div`
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
`;

export const Title = styled.div`
  font-weight: 600;
  color: #8f9191;
  text-align: center;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 13px;
  font-size: 12px;
  background: rgba(238,244,241,0.65);
`;
