import { useEffect, useContext } from 'react'
import useFetch from '../hooks/useFetch'
import { CurrentUserContext } from '../contexts/currentUser'
import useLocalStorage from '../hooks/useLocalStorage'

const CurrentUserChecker = ({ children }: { children: any }) => {

    const [{ responses }, doFetch] = useFetch('user');
    const [, dispatch] = useContext(CurrentUserContext);
    const [token] = useLocalStorage('token');

    useEffect(() => {
        if (!token) {
            dispatch({ type: 'SET_UNAUTHORIZED' })
            return
        }
        doFetch();
        dispatch({ type: 'LOADING' });
    }, [token, dispatch, doFetch])

    useEffect(() => {
        if (!responses) return
        dispatch({ type: 'SET_AUTHORIZED', payload: responses.user })
    }, [responses, dispatch])

    return children
}

export default CurrentUserChecker;