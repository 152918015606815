import * as React from 'react';
import { useEffect, useContext } from 'react'
import { Redirect } from 'react-router-dom'

import useLocalStorage from '../../hooks/useLocalStorage'
import { CurrentUserContext } from '../../contexts/currentUser'

const Logout = () => {

    const [token, setToken] = useLocalStorage('token')
    const [, dispatch] = useContext(CurrentUserContext)

    useEffect(() => {
        setToken('')
        dispatch({ type: 'SET_UNAUTHORIZED' })
    }, [setToken, dispatch])

    return token === '' ? <Redirect to="/" /> : null
}

export default Logout