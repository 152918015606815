import * as React from 'react';
import { Wrapper, Row, Title, TicketNo, TimeString, Status, Description, Container, TicketLink } from './ticketBlock.styles';
import * as dateformat from 'dateformat';
import { ticketColors } from '../../../utils/ticketColors';
import {FC} from "react";

interface Props {
  ticket: MaintenanceTicket;
}

export const TicketBlock: FC<Props> = (props):JSX.Element => {
    const {mainColor, status, bgColor} = ticketColors(props.ticket);
    const timeString =  dateformat(new Date(props.ticket.date), 'mm/dd/yyyy');

    return (
      <TicketLink to={`/ticket/${props.ticket.number}`}>
        <Container>
          <Wrapper color={bgColor}>
            <Row>
              <Title color={mainColor}>{props.ticket.title}</Title>
              <TicketNo color={mainColor}>Ticket #{props.ticket.number}</TicketNo>
            </Row>
            <Row>
              <TimeString>{timeString}</TimeString>
              <Status color={mainColor}>{status}</Status>
              <Description>{props.ticket.description}</Description>
            </Row>
          </Wrapper>
        </Container>
      </TicketLink>
    );
}
