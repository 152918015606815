import * as React from 'react';
import { Page } from '../page';
import { ticketColors } from '../../utils/ticketColors';
import {
  InfoContainer, ColorLine, InfoContent, TicketType, BoldText,
  Row, TicketNo, Description, Status, InfoWrap, InfoHeader, MessageContainer, AddMessageWrap, AddMessageBtn, MessageInput
} from './ticket.styles';
import * as dateformat from 'dateformat';
import { ChatMesssage } from '../../components/blocks/chatMessage/chatMessage';
import { FC, useContext, useEffect, useRef, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { CurrentUserContext } from "../../contexts/currentUser";

interface Message {
  user_id: string;
  name: string;
  date: string;
  text: string;
}

interface Props {
  number: string;
}

export const TicketPage: FC<Props> = (props): JSX.Element => {
  const [messages, setMessages] = useState<Array<any>>([])
  const [ticket, setTicket] = React.useState<MaintenanceTicket>(null)
  const [newMessage, setNewMessage] = React.useState<string>('')
  const [lastMessageId, setLastMessageId] = React.useState<number>(0)
  const [{ responses, errors, isLoading }, doFetch] = useFetch('getTicket/' + props.number)
  const [messageResult, fetchMessage] = useFetch('addMessage')
  const [currentUserState] = useContext(CurrentUserContext)


  useEffect(() => {
    doFetch({
      method: 'POST',
      data: { onlyMessages: false }
    })
  }, [])

  useEffect(() => {
    if (!responses) return
    if (responses.messages) setMessages(responses.messages)
    if (responses.ticket) setTicket(responses.ticket)

    if (responses.lastMessageId) setLastMessageId(responses.lastMessageId)
  }, [responses])

  useEffect(() => {
    if (!messageResult.responses) return
    setNewMessage('')
    refreshMessages()
  }, [messageResult.responses])

  const refreshMessages = () => {
    doFetch({
      method: 'POST',
      data: { onlyMessages: true, lmid: lastMessageId }
    })
  }

  function sendMessage() {
    if (!newMessage) return;
    fetchMessage({
      method: 'POST',
      data: { tid: props.number, message: newMessage }
    })
  }

  useInterval(() => {
    doFetch({
      method: 'POST',
      data: { onlyMessages: true, lmid: lastMessageId }
    })
  }, 10000);

  return (
    <Page title={`Ticket #${props.number}`} tabNavigation={true}>
      {renderInfo()}
      {renderMessages(messages)}
    </Page>
  );


  function renderInfo() {
    if (!ticket) {
      return null;
    }

    const { mainColor, lineColor, status } = ticketColors(ticket);
    const timeString = dateformat(ticket.date, 'mm/dd/yyyy');

    return (
      <InfoContainer>
        <InfoWrap>
          <ColorLine color={lineColor} />
          <InfoContent>
            <Row>
              <InfoHeader>
                <TicketType>{ticket.type}</TicketType>
                <TicketNo>#{ticket.number}</TicketNo>
              </InfoHeader>
            </Row>
            <Row>
              <BoldText>{ticket.title}</BoldText><br />
              <Description>{ticket.description}</Description>
            </Row>
            <Row>
              <BoldText>Submited on {timeString}</BoldText>
            </Row>
            <Row>
              <BoldText>Status: </BoldText><Status color={mainColor}>{status}</Status>
            </Row>
          </InfoContent>
        </InfoWrap>
      </InfoContainer>
    );
  }

  function renderMessages(messages: Message[]) {
    if (!messages) return;
    const messageEls = messages.map((message) =>
      <ChatMesssage
        isMe={message.user_id === currentUserState.currentUser.id}
        author={message.name}
        date={message.date}
        text={message.text}
      />
    );

    return (
      <MessageContainer>
        <AddMessageWrap>
          <MessageInput onChange={e => setNewMessage(e.target.value)} value={newMessage} />
          <AddMessageBtn icon={require('../../../assets/icons/plus.svg')} onClick={sendMessage} disabled={messageResult.isLoading} />
        </AddMessageWrap>
        {messageEls}
      </MessageContainer>
    );
  }

  function useInterval(callback: any, delay: any) {
    const savedCallback: any = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
}
