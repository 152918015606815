import * as React from 'react';
import { PageContainer, PageContent, PageWrap } from './page.styles';
import { TabNavigation } from '../components/blocks/tabs/tabNavigation';
import { Header } from '../components/blocks/header/header';
import { FC, useContext, useEffect, useState } from "react";
import { CurrentUserContext } from "../contexts/currentUser";
import { Redirect } from "react-router-dom";

export interface Props {
    title: string;
    tabNavigation: boolean;
    checkAuth?: boolean;
}

const defaultProps: Props = {
    title: '',
    tabNavigation: false,
    checkAuth: true
}

export const Page: FC<Props> = (props): JSX.Element => {

    const [currentUserState] = useContext(CurrentUserContext);
    const [redirectLogin, setRedirectLogin] = useState(false);

    useEffect(() => {
        document.title = props.title
    }, []);

    useEffect(() => {
        setRedirectLogin(currentUserState.isLoggedIn === false);
        if (props.checkAuth === false) {
            setRedirectLogin(false);
        }
    }, [currentUserState]);

    function renderMenu(): JSX.Element {
        if (!props.tabNavigation) return null;

        return (
            <TabNavigation
                tabs={[
                    { iconURL: require('../../assets/icons/user.svg'), href: '/' },
                    { iconURL: require('../../assets/icons/envelope.svg'), href: '/maintenance' },
                    { iconURL: require('../../assets/icons/cog.svg'), href: '/settings' }
                ]}
            />
        );
    }

    return (
        <PageContainer>
            {redirectLogin ? <Redirect to={'/login'} /> : null}
            <PageWrap>
                <div>
                    <Header userName={'Veranda Baker'} />
                    <PageContent>
                        {props.children}
                    </PageContent>
                </div>
            </PageWrap>
            {renderMenu()}
        </PageContainer>
    );
}

Page.defaultProps = defaultProps;