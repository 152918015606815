import styled, { css } from 'styled-components';

export const Button = styled.button`
  box-sizing: border-box;
  padding: 10px 20px;
  color: white;
  background-color: rgba(38, 125, 224, 1);
  border-radius: 3px;
  border-width: 0;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: rgba(18, 105, 204, 1);
  }
`;

export const FormButton = styled.button`
  box-sizing: border-box;
  padding: 10px 20px;
  color: white;
  background-color: rgba(38, 125, 224, 1);
  border-radius: 3px;
  border-width: 0;
  font-size: 14px;

  ${(props: { active: boolean }) => css`
  background-color: ${props.active ? "rgba(38, 125, 224, 1)" : "rgb(235, 235, 235, 1)"};
  color: ${props.active ? "white" : "#606060"};
  cursor: ${props.active ? "pointer" : "wait"};
`}
`;
