import * as React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LoginPage from './pages/login/login';
import HomePage from './pages/home/home';
import PaymentPage from './pages/payment/payment';
import NewBankAccountPage from './pages/newBankAccount/newBankAccount';
import AutopayPage from './pages/autopay/autopay';
import { AppContainer } from './app.styles';
import { MaintenancePage } from './pages/maintenance/maintenance';
import { TicketPage } from './pages/ticket/ticket';
import { NewTicketPage } from './pages/newTicket/newTicket';
import SubmitConfirmationPage from './pages/submitConfirmation/submitConfirmation';
import PaymentConfirmationPage from './pages/paymentConfirmation/paymentConfirmation';
import SettingsPage from './pages/settings/settings';
import CurrentUserChecker from './utils/currentUserChecker';
import { CurrentUserProvider } from './contexts/currentUser';
import Logout from "./pages/login/logout";
import { RegisterPage } from "./pages/login/register";
import { ForgotPage } from "./pages/login/forgot";
import AutopayConfirmationPage from "./pages/autopayConfirmation/autopayConfirmation";

const App = () => {
  var history = require('connect-history-api-fallback');
  history({
    disableDotRule: true
  })
  return (
    <CurrentUserProvider>
      <CurrentUserChecker>
        <AppContainer>
          <Router>
            <Route exact path='/' render={() => <HomePage />} />
            <Route path='/payment' render={() => <PaymentPage />} />
            <Route path='/new_bank_account' render={() => <NewBankAccountPage />} />
            <Route path='/payment_confirmation' render={(props:any) => <PaymentConfirmationPage {...props} />} />
            <Route path='/autopay' render={() => <AutopayPage />} />
            <Route path='/autopay_confirmation' render={(props:any) => <AutopayConfirmationPage {...props} />} />
            <Route path='/login' render={() => <LoginPage />} />
            <Route path='/forgot/:token?' render={(data: any) => <ForgotPage token={data.match.params.token} />} />
            <Route path='/logout' render={() => <Logout />} />
            <Route path='/maintenance' render={() => <MaintenancePage />} />
            <Route path='/settings' render={() => <SettingsPage />} />
            <Route path='/ticket/:number' render={(data: any) => <TicketPage number={data.match.params.number} />} />
            <Route path='/new_ticket' render={() => <NewTicketPage />} />
            <Route path='/submit_confirmation' render={(props:any) => <SubmitConfirmationPage {...props} />} />
            <Route path='/register/:token' render={(data: any) => <RegisterPage token={data.match.params.token} />} />
          </Router>
        </AppContainer>
      </CurrentUserChecker>
    </CurrentUserProvider>
  );
}

export default App;