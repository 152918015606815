import styled, { css } from 'styled-components';

export const InfoContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
`;

export const InfoWrap = styled.div`
  background: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  width: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
`;

export const ColorLine = styled.div`
  width: 5px;

  ${(props: {color: string}) => css`
    background-color: ${props.color};
  `}
`;

export const InfoContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 20px;

  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  width: 100%;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const TicketType = styled.div`
  flex: 2;
  font-size: 14px;
  color: rgb(62, 60, 59);
  font-weight: 600;
`;

export const TicketNo = styled.div`
  font-size: 14px;
  color: #6f8fca;
`;

export const BoldText = styled.span`
  color: rgb(171, 173, 174);
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 7px;
`;

export const Description = styled.div`
  color: rgb(171, 173, 174);
  font-size: 12px;
  line-height: 16px;
`;

export const Status = styled(BoldText)`
  ${(props: {color: string}) => css`
    color: ${props.color};
  `}
`;

export const InfoHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const AddMessageWrap = styled.div`
  width: 100%;
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const AddMessageBtn = styled.button`
  width: 40px;
  height: 35px;
  border-width: 0;

  cursor: pointer;

  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #4bb43e;

  border-radius: 0 4px 4px 0;
  ${(props: {icon: string}) => css`
    background-image: url("${props.icon}");
  `} 
  &[disabled]:after { content:"..." }
  &[disabled] { background-image: none }
  &:hover {
    background-color: #419c35;
  }
`;
export const MessageInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  border: solid 1px #dfdfdf;
`;